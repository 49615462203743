// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600");
// FontAwesome
@import '../font-awesome.min.css';
// Variables
@import "../front/variables";
@import "resources/assets/libs/jquery-sweetalert/sweetalert.scss";
@import "~@coreui/coreui-pro/scss/coreui";
@import "resources/assets/libs/tempusdominus/src/sass/tempusdominus-bootstrap-4-build.scss";
@import "resources/assets/libs/bootstrap-switch/bootstrap-switch.min.scss";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker";


@import "~dropzone/dist/dropzone";


